"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathExistError = exports.PathError = void 0;
const types_1 = require("./types");
/**
 * Custom error type, thrown by `validateErrorCode`.
 */
class PathError extends Error {
    constructor(msg, res) {
        super(msg);
        this.chain = res.chain;
        this.code = res.errorCode;
        this.codeName = `PathErrorCode.${types_1.PathErrorCode[this.code]}`;
        if (res.options) {
            this.options = res.options;
        }
        this.idx = res.idx;
        this.scope = res.scope;
        Object.setPrototypeOf(this, PathError.prototype);
    }
}
exports.PathError = PathError;
/**
 * Custom error type, thrown by `validateExists`.
 */
class PathExistError extends Error {
    constructor(msg, res, propName) {
        super(msg);
        this.chain = res.chain;
        this.propName = propName;
        if (res.options) {
            this.options = res.options;
        }
        this.scope = res.scope;
        Object.setPrototypeOf(this, PathExistError.prototype);
    }
}
exports.PathExistError = PathExistError;
