"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isClass = void 0;
/**
 * Verifies that 'function'-type parameter represents a class.
 *
 * It determines an ES6 class with 100% accuracy, while an ES5 class is only
 * determined when its name starts with a capital letter.
 *
 * @param func
 * Value of type 'function'
 */
function isClass(func) {
    const s = Function.prototype.toString.call(func);
    return s.startsWith('class ') || (s.startsWith('function ') && s[9] <= 'Z' && s[9] >= 'A');
}
exports.isClass = isClass;
