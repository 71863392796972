"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveIfExists = exports.resolveValue = void 0;
const parsers_1 = require("./parsers");
const validators_1 = require("./validators");
/**
 * High-level path-to-value resolution function, to either return the resolved value or throw a detailed error.
 *
 * @param target
 * Default resolution scope.
 *
 * @param path
 * Resolution path, either as an array of property names, or a dot-separated string.
 * If the path starts with `this`, resolution is against the calling context.
 *
 * @param options
 * Path-parsing options.
 */
function resolveValue(target, path, options) {
    const res = parsers_1.resolvePath.call(this, target, path, options);
    (0, validators_1.validateErrorCode)(res);
    return res.value;
}
exports.resolveValue = resolveValue;
/**
 * Extends validation for missing last property, to throw a detailed error.
 *
 * @param target
 * Default resolution scope.
 *
 * @param path
 * Resolution path, either as an array of property names, or a dot-separated string.
 * If the path starts with `this`, resolution is against the calling context.
 *
 * @param options
 * Path-parsing options.
 */
function resolveIfExists(target, path, options) {
    const res = parsers_1.resolvePath.call(this, target, path, options);
    (0, validators_1.validateErrorCode)(res);
    (0, validators_1.validateExists)(res);
    return res.value;
}
exports.resolveIfExists = resolveIfExists;
