"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathErrorCode = void 0;
/**
 * Error code that's used within type IPathResult.
 */
var PathErrorCode;
(function (PathErrorCode) {
    /**
     * Empty name encountered.
     */
    PathErrorCode[PathErrorCode["emptyName"] = 1] = "emptyName";
    /**
     * Keyword `this` encountered after the start.
     */
    PathErrorCode[PathErrorCode["invalidThis"] = 2] = "invalidThis";
    /**
     * Encountered an asynchronous value or function.
     */
    PathErrorCode[PathErrorCode["asyncValue"] = 3] = "asyncValue";
    /**
     * Encountered a generator function or iterator.
     */
    PathErrorCode[PathErrorCode["genValue"] = 4] = "genValue";
    /**
     * Parsing stopped, after encountering `undefined` or `null` value.
     */
    PathErrorCode[PathErrorCode["stopped"] = 5] = "stopped";
})(PathErrorCode = exports.PathErrorCode || (exports.PathErrorCode = {}));
