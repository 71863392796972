// import variables from '@variables';
import React, { Suspense } from "react";
import { Platform, StyleSheet, View } from "react-native";
import variables from "../../styles/variables";
import { NestedKeyOf } from "../../../utility";
import { deep_value } from "../../utils/deepValue";
import RText from "../RText";
import lazyImport from "../../utils/lazyImport/lazyImport";
import { importIcon } from "./icons";
import { getColorValue, getFillValue } from "../../utils/colors";
import SharedIcon from "./SharedIcon";

export type IconSize = keyof typeof variables.sizes;
// export type IconName = keyof typeof IconList;
// export type FlagIcon = keyof typeof FlagList;
export type Colors = typeof variables.colors;
export type IconFill = NestedKeyOf<Colors>;

// todo - convert three digits into 2 digit flag so we can get the flags
// we'll actually use, rather than all

type Props = {
  name: any; //IconName | FlagIcon;
  size?: IconSize;
  fill?: IconFill | string; // either a color from variables.ts or a hex color string
  style?: any;
  rotate?: number;
  notifications?: number;
  stroke?: IconFill;
  color?: Colors;
  onTabar?: boolean;
};

const eagerLoadList = ["longLogo", "search", "profile", "play", "chevronLeft"];

const Icon = ({
  name,
  style,
  size,
  fill,
  rotate,
  notifications = 0,
  stroke,
  color,
  onTabar = false,
  ...rest
}: Props) => {
  if (eagerLoadList.includes(name)) {
    const IconComponent = require(`./icons/${name}.svg`).default;

    return (
      <SharedIcon
        component={IconComponent}
        style={[
          styles.icon,
          style,
          rotate ? { transform: [{ rotate: `${rotate}deg` }] } : {},
        ]}
        onTabar={onTabar}
        color={color && color}
        fill={fill && getColorValue(fill)}
        stroke={stroke && getColorValue(stroke)}
        {...(size && variables.sizes[size])}
        {...rest}
      />
    );
  }

  // Dynamic import using lazyImport
  const IconComponent = React.useMemo(() => {
    return lazyImport(() => importIcon(name));
  }, [name]);

  if (!IconComponent) return null;

  return (
    <Suspense fallback={<></>}>
      <SharedIcon
        component={IconComponent}
        style={[
          styles.icon,
          style,
          rotate ? { transform: [{ rotate: `${rotate}deg` }] } : {},
        ]}
        color={color && color}
        fill={fill && getColorValue(fill)}
        stroke={stroke && getColorValue(stroke)}
        {...(size && variables.sizes[size])}
        notifications={notifications}
        onTabar={onTabar}
        {...rest}
      />
    </Suspense>
  );
};

/* don't add height and width attrs for web */
const styles =
  Platform.OS === "web"
    ? StyleSheet.create({
        icon: {
          userSelect: "none", // stops any nearby text highlighting
        },
      })
    : StyleSheet.create({
        icon: {
          height: "100%",
          width: "100%",
        },
      });

export default React.memo(Icon);
