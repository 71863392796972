"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateExists = exports.validateErrorCode = void 0;
const types_1 = require("./types");
const errors_1 = require("./errors");
/**
 * Validates `errorCode` within `IPathResult`, to throw `PathError` when it is set.
 *
 * @param res
 * Result to validate.
 */
function validateErrorCode(res) {
    switch (res.errorCode) {
        case types_1.PathErrorCode.emptyName:
            throw new errors_1.PathError('Empty names are not allowed.', res);
        case types_1.PathErrorCode.invalidThis:
            throw new errors_1.PathError(`Keyword 'this' can only be at the start.`, res);
        case types_1.PathErrorCode.asyncValue:
            const asyncName = JSON.stringify(res.chain[res.idx + 1]);
            throw new errors_1.PathError(`Cannot resolve ${asyncName}: async functions and values are not supported.`, res);
        case types_1.PathErrorCode.genValue:
            const genName = JSON.stringify(res.chain[res.idx + 1]);
            throw new errors_1.PathError(`Cannot resolve ${genName}: iterators and generators are not supported.`, res);
        case types_1.PathErrorCode.stopped:
            const stoppedName = JSON.stringify(res.chain[res.idx + 1]);
            throw new errors_1.PathError(`Cannot resolve ${stoppedName} from null/undefined.`, res);
        default:
            break;
    }
}
exports.validateErrorCode = validateErrorCode;
/**
 * Validates `exists` within `IPathResult`, to throw `PathExistError` when it is `false`.
 *
 * @param res
 * Result to validate.
 */
function validateExists(res) {
    if (!res.exists) {
        const lastName = res.chain[res.chain.length - 1];
        throw new errors_1.PathExistError(`Property ${JSON.stringify(lastName)} doesn't exist.`, res, lastName);
    }
}
exports.validateExists = validateExists;
